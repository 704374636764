/* eslint-disable */
<template>
  <div class="container">
    <div class="first-page">
      <div class="back-btn-container">
        <a id="dsb_url" href="#">
          <div id="back_btn">
              <img class="btn_icon" src="@/assets/dashboard2.png" alt="back_btn" width="30px" />
              <h2>Dashboard</h2>
          </div>
        </a>
      </div>
      <h1>Chat with any PDF</h1>
      <div class="drag-box-main">
        <!-- Start loader div -->
        <div class="loader-overlay" v-if="loading">
          <div class="loader"></div>
          <span class="disclaimer_text" v-if="showDisclaimer">
           <b>To ensure optimal performance, ChatPDF undergoes regular optimization.<br> If you encounter upload difficulties, please retry after 1-2 minutes.</b>
          </span>
        </div>
        <!-- End loader div -->
        <div class="drag-box" @dragover="dragover" @dragleave="dragleave" @drop="drop">
          <input type="file" id="file-selector" single class="input-file" @change="onChange" ref="file" accept=".pdf"/>
          <label for="assetsFieldHandle" class="block cursor-pointer">
            <div class="drag-text" @click="showBrowseComputer">
              <img src="@/assets/images/drop-icon.png" alt=""/>
              <br>
              Drop PDF Here
              <button class="upload_btn">Upload Documents</button>
            </div>
          </label>
          <div class="status-display">
            <div v-html="filename_tuncated" class="filename label_mobile"></div>
            <div v-html="filename" class="filename label_desktop"></div>
            <div v-html="filestatus" class="filestatus"></div>
          </div>
          <div class="other-options">
            <div class="left" @click="showBrowseComputer">Browse Computer</div>
            <div class="right" @click="showModal">My Documents</div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="search-box-main">
      <div class="title-box">
        <div class="left seventy">
          <span class="label_desktop">
            Chat with <span v-html="filename_tuncated_dektop"></span>
          </span>
          <span class="label_tab">
            Chat with <span v-html="filename_tuncated_tab"></span>
          </span>
          <span class="label_mobile">
            Chat with <span v-html="filename_tuncated_mobile"></span>
          </span>
        </div>
        <div class="right twenty">
          <div class="clsExport" @click="onClickExportButton">
            <div class="ToolbarButtonExport" id="btn_export">
              <svg
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z" />
              </svg>
            </div>
            <div class="clsExportText">
              Export Conversation
            </div>
          </div>
          <div class="clsCloseX">
            <span @click="closePDF">X</span>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="result-box" id="result-box" ref="result-box">
      </div>
      <div class="prompt-box">
        <input type="text" class="prompt" maxlength="500" placeholder="Ask any question..."
               v-on:keyup.enter="searchFile">
        <button class="submit" @click="searchFile">
          <span role="img" aria-label="send" class="">
            <svg viewBox="64 64 896 896" focusable="false" data-icon="send" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M931.4 498.9L94.9 79.5c-3.4-1.7-7.3-2.1-11-1.2a15.99 15.99 0 00-11.7 19.3l86.2 352.2c1.3 5.3 5.2 9.6 10.4 11.3l147.7 50.7-147.6 50.7c-5.2 1.8-9.1 6-10.3 11.3L72.2 926.5c-.9 3.7-.5 7.6 1.2 10.9 3.9 7.9 13.5 11.1 21.5 7.2l836.5-417c3.1-1.5 5.6-4.1 7.2-7.1 3.9-8 .7-17.6-7.2-21.6zM170.8 826.3l50.3-205.6 295.2-101.3c2.3-.8 4.2-2.6 5-5 1.4-4.2-.8-8.7-5-10.2L221.1 403 171 198.2l628 314.9-628.2 313.2z"></path></svg>
          </span>
        </button>
      </div>
    </div>
    <div class="footer">
      <a href="https://ai-pro.org/disclaimer/" class="disclaimer" target="_blank" rel="noreferrer">
          AI-PRO is powered by OpenAI. We are neither affiliated nor related to OpenAI.
      </a>
    </div>
    <Modal v-show="isModalVisible" @close="closeModal" @loadDocument="loadDocument" @deleteDocument="deleteDocument"/>
    <ModalDelete v-show="isModalDeleteConfirmVisible" @close="closeModalDelete" @confirmDeleteDocument="confirmDeleteDocument" :docToDelete="docToDelete"/>

    <div class="loader-box"><img class="loader-box-img" src="@/assets/images/loader.gif"></div>
  </div>
</template>

<script>
/* eslint-disable */
import CookieHelper from "@/helpers/cookie-helper";
import axios from 'axios';
import $ from "jquery";
import Modal from '@/components/Modal.vue';
import ModalDelete from '@/components/ModalDelete.vue';
import { encodingForModel } from "js-tiktoken";

export default {
  name: 'ChatPDF',
  components: {Modal, ModalDelete},

  data() {
    return {
      filelist: [],
      file: null,
      filename: '',
      filename_tuncated_dektop: '',
      filename_tuncated_tab: '',
      filename_tuncated_mobile: '',
      filestatus: '',
      userid: '',
      temperature: '',
      template: '',
      paramResponse: '',
      paramRowID: '',
      typeWriterCounter: 0,
      mydocuments: [],
      isModalVisible: false,
      isModalDeleteConfirmVisible: false,
      aiwp_logged_in: '',
      docToDelete: '',
      showDisclaimer: false,
      mode: 'live',
      userIp: '',
    }
  },

  async created() {

    const urlParams = new URLSearchParams(window.location.search);
    let mode = urlParams.get('mode');
    let temperature = process.env.VUE_APP_TEMPERATURE ? process.env.VUE_APP_TEMPERATURE : '0.2';
    let template =  process.env.VUE_APP_TEMPLATE ? process.env.VUE_APP_TEMPLATE : '1'; ;

    if (mode!=null && mode!=''){
      CookieHelper.setCookie("mode", mode, 30);
    }
    if (temperature!=null && temperature!=''){
      CookieHelper.setCookie("temperature", temperature, 30);
    }
    if (template!=null && template!=''){
      CookieHelper.setCookie("template", template, 30);
    }

    this.userid = CookieHelper.getFlag("userid", "");
    this.mode = CookieHelper.getFlag("mode", "live");

    if (this.mode==='test'){
      this.temperature = CookieHelper.getFlag("temperature", temperature);
      this.template = CookieHelper.getFlag("template", template);
    }else{
      this.temperature = temperature;
      this.template = template;
    }

    console.log("mode:",this.mode,"temperature",this.temperature,"template:",this.template);

    let intialUsage = sessionStorage.getItem('tried_remote');
    const limitedUsage = sessionStorage.getItem('WcvYPABR');
    sessionStorage.setItem("intialUsage", intialUsage);

    if (this.userid == '') {
      this.userid = Math.floor(100000000 + Math.random() * 900000000);
      CookieHelper.setCookie("userid", this.userid, 30);
    }

    let aiwp_logged_in =  CookieHelper.getCookie("aiwp_logged_in");
    if (aiwp_logged_in!==undefined && aiwp_logged_in !== ''){
      console.log("ai:",aiwp_logged_in);
      CookieHelper.setCookie("aiwp_info", aiwp_logged_in, 30);
    }

    setTimeout(function(){
      this.aiwp_logged_in = CookieHelper.getCookie('user_email');
      if (this.aiwp_logged_in!='' && this.aiwp_logged_in.indexOf('free') == -1 && !(window.self !== window.top)){
        $('#back_btn').show();
      }
      console.log("user",this.aiwp_logged_in)
    }, 2000);

  },
  async mounted() {
    this.initData();
    this.changeUrl();

    $('#back_btn').hide();

    var perfEntries = performance.getEntriesByType("navigation");
    if (perfEntries[0].type === "back_forward") {
        location.reload();
    }

    this.userIp = await this.getUserIP();
  },

  methods: {
    scrollToBottom() {
      if (this.$refs['result-box']) {
        this.$refs['result-box'].scrollTop = this.$refs['result-box'].scrollHeight;
      } else {
        console.error("Ref 'result-box' not found.");
      }
    },

    onChange() {
      console.log(this.filelist)
      this.filelist = [this.$refs.file.files];
      this.uploadFile();
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // console.log("dragleave",event)
      // Add some visual fluff to show the user can drop its files
    },
    dragleave(event) {
      event.preventDefault();
      // console.log("dragleave",event)
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    showBrowseComputer() {
      $("#file-selector").click();
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showModalDelete() {
      this.isModalDeleteConfirmVisible = true;
    },
    closeModalDelete(){
      this.isModalDeleteConfirmVisible = false;
    },
    loadDocument(document) {
      this.filename = document;

      if (this.filename.trim().length>20){
        this.filename_tuncated_mobile = this.filename.slice(0, 20)+"...";
      }else{
        this.filename_tuncated_mobile = this.filename;
      }

      if (this.filename.trim().length>32){
        this.filename_tuncated_tab = this.filename.slice(0, 32)+"...";
      }else{
        this.filename_tuncated_tab = this.filename;
      }

      if (this.filename.trim().length>48){
        this.filename_tuncated_dektop = this.filename.slice(0, 48)+"...";
      }else{
        this.filename_tuncated_dektop = this.filename;
      }

      this.closeModal();
      $('.first-page').hide();
      $('.search-box-main').show();
      this.initData();
    },
    deleteDocument(document){
      console.log('document',document);
      this.docToDelete = document;
      this.closeModal();
      this.showModalDelete();
    },
    confirmDeleteDocument(doc){
      let mydocuments = CookieHelper.getFlag("documents", "");
      console.log("myDocuments:",mydocuments,"doc:",doc)
      if (mydocuments != undefined && mydocuments != '') {
        let arr_mydocu = JSON.parse(mydocuments)
        let index = arr_mydocu.indexOf(doc);
        if (index !== -1) {
          arr_mydocu.splice(index, 1);
          let json_str = JSON.stringify(arr_mydocu);
          CookieHelper.setCookie("documents", json_str, 30);
          location.reload();
        }
      }
    },
    async uploadFile() {
      this.loading = true; // Show loader

      setTimeout(() => {
        this.showDisclaimer = true;
      }, 5000)

      $(".drag-box-main").addClass("disable-div");

      this.file = this.$refs.file.files[0];
      this.filename = this.file.name.replace(' ', '');

      let arr_mydocu = [];
      let mydocuments = CookieHelper.getFlag('documents')
      if (mydocuments != undefined && mydocuments != '') {
        arr_mydocu = JSON.parse(mydocuments)
        let doc_ctr = this.getLastOccurrence(arr_mydocu, this.filename)
        if (doc_ctr > 1) {
          this.filename = this.filename.replace(".", "_" + doc_ctr + ".");
        }
      }

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('filename', this.filename);
      formData.append('userid', this.userid);
      formData.append('action', 'upload');

      const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
      let upload_status = 0;
      let upload_message = '';

      this.filestatus = 'Uploading File...';
      await axios.post(process.env.VUE_APP_API_URL + 'chatpdf', formData, {headers}).then((res) => {
        upload_status = res.data.success;
        upload_message = res.data.message;
      });

      if (upload_status != '1') {
        $(".drag-box-main").removeClass("disable-div");
        if (upload_message=='upgrade'){
          window.location.href = process.env.VUE_APP_REDIRECT_UPGRADE_URL;
          return;
        }else if(upload_message=='login'){
          this.filestatus = 'Upload Failed. Please re-login.';
          return;
        }else if(upload_message=='relogin'){
          this.filestatus = 'Upload Failed. Please re-login.';
          return;
        }else{
          this.filestatus = 'Failed to upload';
          return;
        }
      }

      let formEmbd = new FormData();
      formEmbd.append('filename', this.filename);
      formEmbd.append('userid', this.userid);
      formEmbd.append('action', 'create');

      const headersEmbd = {'Content-Type': 'application/x-www-form-urlencoded'};

      if (this.filename.trim().length>11){
        this.filename_tuncated_mobile = this.filename.slice(0, 11)+"...";
      }else{
        this.filename_tuncated_mobile = this.filename;
      }

      if (this.filename.trim().length>32){
        this.filename_tuncated_tab = this.filename.slice(0, 32)+"...";
      }else{
        this.filename_tuncated_tab = this.filename;
      }

      if (this.filename.trim().length>48){
        this.filename_tuncated_dektop = this.filename.slice(0, 48)+"...";
      }else{
        this.filename_tuncated_dektop = this.filename;
      }

      this.filestatus = 'Processing Data...';
      await axios.post(process.env.VUE_APP_API_URL + 'chatpdf', formEmbd, {headersEmbd}).then((res) => {
        console.log(res)
        if (res.data.success == '1') {
          $('.first-page').hide();
          $('.search-box-main').show();
          this.initData();

          let arr_mydocu = [];
          let mydocuments = CookieHelper.getFlag('documents')
          if (mydocuments != undefined && mydocuments != '') {
            arr_mydocu = JSON.parse(mydocuments)
          }
          if (!arr_mydocu.includes(this.filename)) {
            arr_mydocu.push(this.filename)
          }

          var json_str = JSON.stringify(arr_mydocu);
          CookieHelper.setCookie("documents", json_str, 30);

          console.log('processing success')
        } else {
          $(".drag-box-main").removeClass("disable-div");
          this.filestatus = 'Failed to process';
        }
        this.loading = false; // Hide loader
      });
    },
    getLastOccurrence(docs, filename) {
      let counter = 1;
      for (let i = 0; i < docs.length; i++) {
        let file_list = docs[i].split('.');
        let file = file_list[0].split('_');
        let file_raw = filename.split('.');
        if (file[0] == file_raw[0]) {
          console.log('xxx');
          counter += 1;
        }
      }
      return counter;
    },
    appendToChat(response, rowid) {
      let previousChats = $(".result-box").html()
      $('.prompt').val('');
      $(".prompt").attr("disabled", "disabled");
      $(".submit").attr("disabled", "disabled");

      let chatToAppendHuman = '<div class="chat-row human"><div class="message">' + response + '</div></div>'
      let chatToAppendAi = '<div class="chat-row ai"><div class="message" id="' + rowid + '"></div></div>'
      $(".result-box").html(previousChats + chatToAppendHuman + chatToAppendAi)
      $('#' + rowid).html($('.loader-box').html());

      // Call scrollToBottom after modifying chatToAppendAi
      this.scrollToBottom();

      // Watch for changes in the content of message div
      let observer = new MutationObserver(() => {
        // Scroll to bottom whenever the content changes
        this.scrollToBottom();
      });
      observer.observe(document.getElementById(rowid), { childList: true, subtree: true });
    },
    appendToChatInitial(rowid) {
      console.log(rowid)
      let previousChats = $(".result-box").html()
      $('.prompt').val('');
      $(".prompt").attr("disabled", "disabled");
      $(".submit").attr("disabled", "disabled");

      let chatToAppendAi = '<div class="chat-row ai"><div class="message" id="' + rowid + '"></div></div>'
      $(".result-box").html(previousChats + chatToAppendAi)
      console.log(chatToAppendAi)
      $('#' + rowid).html($('.loader-box').html());
    },
    closePDF() {
      location.reload();
    },
    typeWriter(cb = () => {
    }) {
      if (this.typeWriterCounter < this.paramResponse.length) {
        if (this.paramResponse.charAt(this.typeWriterCounter) == '|') {
          document.getElementById(this.paramRowID).innerHTML += '<br>';
        } else {
          document.getElementById(this.paramRowID).innerHTML += this.paramResponse.charAt(this.typeWriterCounter);
        }
        this.typeWriterCounter++;
        setTimeout(() => this.typeWriter(cb), 20);
      } else {
        setTimeout(function(){
          $(".clsExport").removeClass("disable-div");
        }, 3000);

        this.typeWriterCounter = 0;
        cb();
      }
    },
    enableChatBoxAndSendButton() {
      $(".prompt").removeAttr("disabled");
      $(".submit").removeAttr("disabled");
    },
    async initData() {
      const headersEmbd = {'Content-Type': 'application/x-www-form-urlencoded'};

      if (this.filename == '') {
        return;
      }

      let rowid = 0;
      let searchEmbd2 = new FormData();
      searchEmbd2.append('filename', this.filename);
      searchEmbd2.append('userid', this.userid);
      searchEmbd2.append('prompt', 'provide top 3 questions i can ask');
      searchEmbd2.append('action', 'search');
      searchEmbd2.append('template', '0');

      rowid = Math.floor(100000000 + Math.random() * 900000000);
      this.appendToChatInitial(rowid)

      $(".clsExport").addClass("disable-div");

      await axios.post(process.env.VUE_APP_API_URL + 'chatpdf', searchEmbd2, {headersEmbd}).then((res) => {
        if (res.data.success == '1') {
          this.paramResponse = 'Here are some sample questions you can ask:|' + res.data.data;
          this.paramRowID = rowid
          this.typeWriterCounter = 0;
          $('#' + rowid).html('');
          this.typeWriter(this.enableChatBoxAndSendButton);
        } else {
          if (res.data.message=='upgrade'){
            window.location.href = process.env.VUE_APP_REDIRECT_UPGRADE_URL;
            return;
          }else if(res.data.message=='login'){
            $('#' + rowid).html('Please re-login.');
          }else if(res.data.message=='relogin'){
            $('#' + rowid).html('Please re-login.');
          }else{
            $('#' + rowid).html(res.data.data);
          }
        }
      });

      this.enableChatBoxAndSendButton();
    },
    async searchFile() {
      let prompt = $('.prompt').val().trim();

      if (prompt == '') {
        return;
      }

      /***************/



      const TPLogicRun = window.TPLogicRun;
      console.log("TPLogicRun", typeof TPLogicRun);
      if (typeof TPLogicRun === 'function') {
        TPLogicRun();
      } else {
      console.error("TPLogicRun is not a function");
      }


      btutil_getChatUsage();
      let maxTokens = btutilCommon_getCookie("mucnxwlyxt");
      if (maxTokens==='1'){
        return;
      }

      /***************/

      let searchEmbd = new FormData();
      searchEmbd.append('filename', this.filename);
      searchEmbd.append('userid', this.userid);
      searchEmbd.append('prompt', prompt);
      searchEmbd.append('action', 'search');
      searchEmbd.append('ipdflu', this.userIp);
      searchEmbd.append('aiwp_logged_in', CookieHelper.getCookie("aiwp_logged_in"));
      searchEmbd.append('WcvYPABR', CookieHelper.getCookie("WcvYPABR"));

      searchEmbd.append('temperature', this.temperature);
      searchEmbd.append('template', this.template);

      const headersEmbd = {'Content-Type': 'application/x-www-form-urlencoded'};

      let rowid = Math.floor(100000000 + Math.random() * 900000000);

      this.appendToChat(prompt, rowid);

      $(".clsExport").addClass("disable-div");
      let askAI = true;

      // await axios.post(process.env.VUE_APP_API_URL + 'moderations', searchEmbd, {headersEmbd}).then((res) => {
      //   if(res.data.results[0].flagged) {
      //     askAI = false;
      //     this.paramResponse = "I'm sorry, but I can't assist with that. We want everyone to use our tool safely and responsibly. If you have any other questions or need advice on a different topic, feel free to ask.";
      //     this.paramRowID = rowid
      //     this.typeWriterCounter = 0;
      //     $('#' + rowid).html('');
      //     this.typeWriter(this.enableChatBoxAndSendButton);
      //   }
      // }).catch((error) => {
      //   console.log(error);
      //   askAI = false;
      //   this.paramResponse = "Something went wrong. Please try again later.";
      //   this.paramRowID = rowid
      //   this.typeWriterCounter = 0;
      //   $('#' + rowid).html('');
      //   this.typeWriter(this.enableChatBoxAndSendButton);
      // });

      if(askAI) {
        await axios.post(process.env.VUE_APP_API_URL + 'chatpdf', searchEmbd, {headersEmbd}).then((res) => {
          if (res.data.success == '1') {
            this.paramResponse = res.data.data;
            this.paramRowID = rowid
            this.typeWriterCounter = 0;
            $('#' + rowid).html('');
            this.typeWriter(this.enableChatBoxAndSendButton);
            //
            let usage = {};
            let enc = null;
            try {
              enc = encodingForModel('gpt-3.5-turbo');
              usage.prompt_tokens = (enc.encode(prompt)).length;
              usage.completion_tokens = (enc.encode(this.paramResponse)).length;
              usage.total_tokens = usage.prompt_tokens + usage.completion_tokens;

              // TOKEN USAGE
              btutil_setChatUsage('chatpdf', usage.prompt_tokens, usage.total_tokens);
            } catch (e) {
              console.log('Error encoding prompt text', e);
            }
          } else {
            if(res.data.status == "limited"){
              btutil_modalRegisterUpgrade();
            }
            $('#' + rowid).html(res.data.data);
            $(".clsExport").removeClass("disable-div");
            this.enableChatBoxAndSendButton();
          }
        }).catch((error) => {
          if (error.response && error.response.status === 429) {
            this.paramResponse = 'Sorry, too many requests. Please try again in a bit!';
            this.paramRowID = rowid
            this.typeWriterCounter = 0;
            $('#' + rowid).html('');
            $('#' + rowid).addClass('italic');

            this.typeWriter(this.enableChatBoxAndSendButton);
          } else {
            console.log('Error', error.message);
          }

          console.log(error.config);
        });
      }
    },

    changeUrl() {
        const back_btn = document.getElementById("back_btn");
        const referrer = document.referrer;
        const invalid_referrer = referrer === '' || referrer === window.location.href;

        if (invalid_referrer || window.self !== window.top) {
          if (back_btn){
            back_btn.style.display = "none";
          }
        }

        if (!invalid_referrer){
          const a_dsb_link = document.getElementById('dsb_url');
          const referrer_url = new URL(referrer);
          a_dsb_link.href = referrer_url.origin +'/my-account';
        }
    },

    responseToExport(){
      let response_to_export = document.querySelector(".result-box");

      if (response_to_export) {
        let response = '';
        for(var i = 0; i < response_to_export.children.length; i++) {
          let profile = "";
          if(response_to_export.children[i].classList.contains("ai")) {
            profile = profile + "<div style='margin-bottom: 10px'><b>[ChatPDF]</b> "+ response_to_export.children[i].querySelector(".message").innerHTML + "</div>";
          } else {
            profile = profile + "<div style='margin-bottom: 10px'><b>[User]</b> "+ response_to_export.children[i].querySelector(".message").innerHTML +"</div>";
          }
          response = response + profile;
        }
        return response;
      }
    },

    onClickExportButton(){
      let modal = document.querySelector(".export-modal-container");
      const response = this.responseToExport();
      if (!response) return;

      if (!modal) {
        const btutil_buildExportModal = window.btutil_buildExportModal;

        modal = btutil_buildExportModal(this.onClickExportToPDF);
        document.body.appendChild(modal);
      }
      modal.classList.add("active");
    },

    onClickExportToPDF(){
      let response = this.responseToExport();
      generatePDF(response, "export");
    },

    async getUserIP() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error); 
        return '';
      }
    },
  }
}
</script>
<style>
  .footer {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    margin: 0;
    bottom: 0;
    max-width: none;
    display: flex;
  }

  @media (max-height: 500px) {
    .footer {
      display: none;
    }
  }

  .footer .disclaimer {
      padding: 10px 20px;
      font-size: 12px;
      text-decoration: underline;
      color: #515051;
      margin: 0 auto;
  }
/* .seventy {width: 70%;} */
/* .twenty {width: 25%;} */
.upload_btn {
  background: #4285f4;
  color: #fff;
  padding: 10px 15px;
  font-weight: 700;
  margin: 15px auto;
  display: block;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  font-size: 16px;
}
 .clsExport {
  display: inline-block;
  background-color: #06603f;
  width: 168px;
  border: 1px solid #06603f;
  border-radius: 5px;
  height: 25px;
  padding-top: 5px;
  margin-right: 10px;
  float: left;
  color: #fff;
  cursor: pointer;
 }

 .clsExport:hover {
  background-color: #35aa4f;
 }

.ToolbarButtonExport {
  display: inline-block;
  color: #fff;
}

.clsExportText {
  display: inline-block;
  color: #fff;
  margin-left: 10px;
}

.clsClose {
  display: inline-block;
}

.clsCloseX{
  display: inline-block;
  width: 30px;
  background: #06603f;
  font-size: 14px;
  color: #ffffff;
  border-radius: 5px;
  font-weight: bold;
  font-family: sans-serif;
  padding: 7px 0px 0 0;
  right: 20px;
  float: right;
  text-align: center;
  height: 25px;
  cursor: pointer;
}
.clsCloseX:hover{
  background: #35aa4f;
}
.label_desktop {
    display: block;
  }
  .label_tab {
    display: none;
  }
  .label_mobile {
    display: none;
  }


@media only screen and (max-width: 1024px) {
  .clsExportText {
    display: none;
  }
  .clsExport {
    width: 40px !important;
  }

  .label_desktop {
    display: none;
  }
  .label_tab {
    display: block;
  }
  .label_mobile {
    display: none;
  }

}

@media only screen and (max-width: 768px) {
  .clsExportText {
    display: none;
  }
  .clsExport {
    width: 40px !important;
  }

  .label_desktop {
    display: none;
  }
  .label_tab {
    display: none;
  }
  .label_mobile {
    display: block;
  }

}


@media only screen and (max-width: 480px) {

  .clsExport {
    /* margin-top: 10px; */
  }
  .title-box .right {
    padding-right: 0px !important;
  }
  .clsClose, .clsCloseX {
    margin-right: 8px;
  }

  .title-box .left {
    padding-left: 9px !important;
    font-size: 16px;
    margin-top: -10px;
  }
  .label_mobile {
    padding-top: 18px !important;
    padding-left: 5px;
  }
}


@media only screen and (max-width: 272px) {
  .clsClose,  .clsCloseX {
    margin-top: 5px;
  }
}

.loader-box {
  display: none;
}

.loader-box-img {
  width: 20px;
}

.first-page {
  /*  display: none;*/
}

.container {
  width: 100%;
  height: 100%;
}

.input-file {
  display: none;
}

.filestatus {
  font-size: 12px;
  padding: 0 30px;
  display: block;
  line-height: 1.3;
}

.filename {
  font-size: 12px;
  padding: 0 30px;
  display: block;
  line-height: 1.3;
}

.status-display {
  min-height: 45px;
  /* margin-top: 120px; */
}

/**chat box**/
.chat-row {
  display: flex;
}

.chat-row.ai {
  justify-content: flex-start;
  margin-bottom: 10px;
}

.chat-row.human {
  justify-content: flex-end;
  margin-bottom: 10px;
}

.chat-row.ai .message {
  background-color: #f9f9fe;
  padding: 10px;
  box-shadow: 0 0 3px #d1d2da;
  border-radius: 8px;
  max-width: 500px;
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

.chat-row.human .message {
  background-color: #1677ff;
  color: #fff;
  padding: 10px;
  max-width: 500px;
  box-shadow: 0 0 3px #d1d2da;
  border-radius: 8px;
  text-align: justify;
  font-size: 14px;
}


.search-box-main {
  display: none;
  width: 53%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
}

.search-box-main .title-box {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 1px;
  border-color: #a0a0a0;
  border-style: solid;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 19px;
}

.clear {
  clear: both;
}

.title-box .left {
  float: left;
  font-weight: bold;
  padding-left: 20px;
}

.title-box .right {
  float: right;
  padding-right: 20px;
  font-size: 14px;
  color: #1677ff;
}

.right span {
  cursor: pointer;
}

.search-box-main .result-box {
  height: calc(100% - 160px);
  padding: 20px;
  overflow-y: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.search-box-main .prompt-box {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 9px;
  width: 100%;
}

.search-box-main .prompt {
  width: calc(100% - 80px);
  height: 36px;
  vertical-align: middle;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 1px;
  border-right: 0px;
  border-style: solid;
  border-color: #a0a0a0;
  border-radius: 5px 0 0 5px;
  padding: 0px 8px 0px 8px;
}

.search-box-main .submit {
  width: 52px;
  height: 38px;
  vertical-align: middle;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 1px;
  border-style: solid;
  border-color: #06603f;
  border-radius: 0 5px 5px 0;
  background-color: #06603f;
  padding-top: 5px;
  color: #fff;
  cursor: pointer;
}

.export-modal svg {
    fill: #06603f !important;
}

.search-box-main .submit:hover {
  background-color: #35aa4f;
}

.prompt:focus {
  outline: none !important;
  border-color: #a0a0a0;
}

@media only screen and (min-width: 1760px) {
  .search-box-main {
    width: 53% !important;
  }
}

@media only screen and (min-width: 1201px) {
  .search-box-main {
    width: 83%;
  }
}

@media only screen and (max-width: 1200px) {
  .search-box-main {
    width: 93%;
  }
}

/**upload box**/
.drag-box-main {
  width: 50%;
  padding: 0px;
  border-radius: 8px;
  margin: 0 auto;
  background: #fff;
  height: 250px;
  position: relative;/**For loader**/
}

@media only screen and (max-width: 700px) {
  .drag-box-main {
    width: 90%;
  }
}

.drag-box {
  background: rgba(0, 0, 0, 0.02);
  width: 100%;
  border: 1px dashed #a0a0a0;
  border-radius: 8px;
  background: #fff;
  height: 100%;
}

.other-options {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.other-options .left {
  float: left;
  cursor: pointer;
  font-size: 14px;
  color: #1677ff;
  margin-left: 10px;
}

.other-options .right {
  float: right;
  cursor: pointer;
  font-size: 14px;
  color: #1677ff;
  margin-right: 10px;
  margin-bottom: 10px;
}

.first-page h1 {
  color: #000;
  margin-top: 50px;
  margin-bottom: 50px;
}

.drag-text {
  font-size: 15px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 0px;
  padding-left: 25px;
  padding-right: 23px;
  cursor: pointer;
}

.drag-text img {
  width: 35px;
  margin-bottom: 15px;
}

.disable-div {
  pointer-events: none;
}

.italic {
  font-style: italic;
}
#dsb_url,#dsb_url:hover,#dsb_url:visited {
  all: unset;
}
.back-btn-container {
  position: absolute;
  bottom: 0;
  left: 130px;
  top: 56px;
  width: auto;
  height: 80px;
  background-color: transparent;
  color: #000;
}

.back-btn-container h2 {
  z-index: 999 !important;
  margin-top: 0;
  display: inline;
  font-weight: 300;
  padding-left: 15px;
  font-size: 23px;
  position: relative;
}

.back-btn-container p {
  margin-bottom: 0;
}

#back_btn{
  cursor: pointer;
}
#back_btn img{
  vertical-align: bottom;
}

.export-modal-container {
  font-family:'poppins', Arial, Helvetica, sans-serif !important
}
@media screen and (max-width: 945px) {
  .back-btn-container h2 {
       display: none;
  }
  .back-btn-container {
       padding:0;
       width: 50px;
       height: 50px;
       top: 20px;
       left: 0;
  }
}

/* CSS for loader. */
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 101%;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust the opacity as needed */
  z-index: 9999; /* Ensure the loader is on top of other content */
  display: grid;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
